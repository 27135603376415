
import { DELIVERY_OPTIONS, MENUS } from "./index";

export const OFFERS_ONSITE = {
    tasting: {
        title: "Complimentary tasting",
        description:
          "This quote qualifies for complimentary tasting! We will send you a link to schedule your tasting session.",
        minimum: 1700,
        isActive: true,
        offeredOnDays: [0, 1, 2, 3, 4],
        offeredMonths: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
        offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_3, MENUS.MENU_4],
        occasions: ["all"],
        dontShowIfItemsSelected: [],
        isSelectable: false
      },
      tastingSaturday: {
        title: "Complimentary tasting",
        description:
          "This quote qualifies for complimentary tasting! We will send you a link to schedule your tasting session.",
        minimum: 1900,
        isActive: true,
        offeredOnDays: [5, 6],
        offeredMonths: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
        offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_3, MENUS.MENU_4],
        occasions: ["all"],
        dontShowIfItemsSelected: [],
        isSelectable: false
      },
      tastingJanFeb: {
        title: "Complimentary tasting",
        description:
          "This quote qualifies for complimentary tasting! We will send you a link to schedule your tasting session.",
        minimum: 1500,
        isActive: true,
        offeredOnDays: [0, 1, 2, 3, 4, 5, 6, 7],
        offeredMonths: [0, 1],
        deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
        offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_3, MENUS.MENU_4],
        occasions: ["all"],
        dontShowIfItemsSelected: [],
        isSelectable: false
      },  
      churros: {
        title: "Complimentary Churros",
        description:
          "If you place an order similar to this quote, you qualify for complimentary Churros!",
        minimum: 3000,
        isActive: true,
        offeredOnDays: [0, 1, 2, 3, 4, 5],
        offeredMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
        offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_3, MENUS.MENU_4],
        occasions: ["all"],
        dontShowIfItemsSelected: [700],
        isSelectable: true
      },
      churrosSaturday: {
        title: "Complimentary Churros",
        description:
          "If you place an order similar to this quote, you qualify for complimentary Churros!",
        minimum: 3000,
        isActive: true,
        offeredOnDays: [6],
        offeredMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
        offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_3, MENUS.MENU_4],
        occasions: ["all"],
        dontShowIfItemsSelected: [700],
        isSelectable: true
      },
      onsiteHolidayDiscount150: {
        title: "$150.00 off",
        description: "If you place an order similar to this quote, you qualify for $150.00 off!",
        minimum: 1100,
        isActive: true,
        offeredOnDays: [0, 1, 2, 3, 4, 5],
        offeredMonths: [0, 1, 11],
        deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
        offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3],
        occasions: ["all"],
        dontShowIfItemsSelected: [],
        isSelectable: true
      },
      onsiteHolidayDiscount200: {
        title: "$200.00 off",
        description: "If you place an order similar to this quote, you qualify for $200.00 off!",
        minimum: 2200,
        isActive: true,
        offeredOnDays: [0, 1, 2, 3, 4, 5],
        offeredMonths: [0, 1, 11],
        deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
        offeredToMenus: [MENUS.MENU_4],
        occasions: ["all"],
        dontShowIfItemsSelected: [],
        isSelectable: true
      },
      onsiteHolidayFreeMenu1Upgrade: {
        title: "FREE Menu Upgrade to Menu 2",
        description: "If you place an order similar to this quote, you qualify for FREE Menu Upgrade!",
        minimum: 1500,
        isActive: true,
        offeredOnDays: [0, 1, 2, 3, 4, 5],
        offeredMonths: [0, 1, 11],
        deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
        offeredToMenus: [MENUS.MENU_1],
        occasions: ["all"],
        dontShowIfItemsSelected: [],
        isSelectable: true
      },
      onsiteHolidayFreeMenu2Upgrade: {
        title: "FREE Menu Upgrade to Menu 3",
        description: "If you place an order similar to this quote, you qualify for FREE Menu Upgrade!",
        minimum: 1500,
        isActive: true,
        offeredOnDays: [0, 1, 2, 3, 4, 5],
        offeredMonths: [0, 1, 11],
        deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
        offeredToMenus: [MENUS.MENU_2],
        occasions: ["all"],
        dontShowIfItemsSelected: [],
        isSelectable: true
      },
}