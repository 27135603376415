export const OCCASIONS = {
  BIRTHDAY: {
    value: "Birthday",
    label: "Birthday",
  },
  WEDDING: {
    value: "Wedding",
    label: "Wedding",
  },
  CORPORATE: {
    value: "Corporate",
    label: "Corporate",
  },
  BACK_YARD_PARTY: {
    value: "Back Yard Party",
    label: "Back Yard Party",
  },
  CELEBRATION_OF_LIFE: {
    value: "Celebration of Life",
    label: "Celebration of Life",
  },
  SCHOOL_EVENT: {
    value: "School Event",
    label: "School Event",
  },
  HOLIDAY: {
    value: "Holiday Party",
    label: "Holiday Party",
  },
  BABY_SHOWER: {
    value: "Baby Shower",
    label: "Baby Shower",
  },
  OTHER: {
    value: "Other",
    label: "Other",
  },
};

export const MENUS = {
  MENU_1: 0,
  MENU_2: 1,
  MENU_3: 2,
  MENU_4: 2,

};

export const DELIVERY_OPTIONS = {
  DROP_OFF: 0,
  PICK_UP: 1,
  ON_SITE: 2,
};

export const DELIVERY_TYPES = {
  DROP_OFF: {
    value: "Drop off per person",
    label: "Drop off per person",
  },
  PICKUP: {
    value: "Pickup per person",
    label: "Pickup per person",
  },
  ON_SITE: {
    value: "On Site",
    label: "On Site",
  },
};

// Special offers things to offer
export const THINGS_TO_OFFER = {
  complimentaryTasting: {
    title: "Complimentary Tasting",
    description: "",
    dontShowIfItemsSelected: [],
    offeredToMenus: [0, 1, 2, 3],
  },
  complimentaryAquaFresca: {
    title: "Complimentary Aqua Fresca",
    description: "",
    dontShowIfItemsSelected: [210],
    offeredToMenus: [0, 1, 2, 3],
  },
  complimentaryChurros: {
    title: "Complimentary Churros",
    description: "",
    dontShowIfItemsSelected: [700],
    offeredToMenus: [0, 1, 2, 3],
  },
  complimentaryQuesadillas: {
    title: "Complimentary Quesadillas",
    description: "",
    dontShowIfItemsSelected: [112, 113, 114, 605],
    offeredToMenus: [0, 1, 2, 3],
  },
  complimentaryPicoDeGallo: {
    title: "Complimentary Pico de Gallo (Taco Bar)",
    description: "",
    dontShowIfItemsSelected: [610, 103],
    offeredToMenus: [0, 1, 2, 3],
  },
  freeMenu1Upgrade: {
    title: "Free Menu Upgrade to Menu 2",
    description: "",
    dontShowIfItemsSelected: [],
    offeredToMenus: [0],
  },
  freeMenu2Upgrade: {
    title: "Free Menu Upgrade to Menu 3",
    description: "",
    dontShowIfItemsSelected: [],
    offeredToMenus: [1],
  },
};
