
import { DELIVERY_OPTIONS, MENUS } from "./index";

export const OFFERS_DROPOFF = {
  deliveryGuacAndChips: {
    title: "Chunky Guacamole & Chips",
    description:
      "If you place an order similar to this quote, you qualify for complimentary Chunky Guacamole & Chips!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [0, 1, 2, 3, 4, 5],
    offeredMonths: [0, 1],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_4],
    occasions: ["all"],
    dontShowIfItemsSelected: [700],
    isSelectable: true
  },
  deliveryChurros: {
    title: "Complimentary Churros",
    description: "If you place an order similar to this quote, you qualify for complimentary Churros!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [0, 1, 2, 3, 4, 5],
    offeredMonths: [0, 1],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_4],
    occasions: ["all"],
    dontShowIfItemsSelected: [700],
    isSelectable: true
  },
  deliveryHolidayFreeMenu1Upgrade: {
    title: "FREE Menu Upgrade to Menu 2",
    description: "If you place an order similar to this quote, you qualify for FREE Menu Upgrade!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [0, 1, 2, 3, 4, 5],
    offeredMonths: [0, 1],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_1],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: true
  },
  deliveryHolidayFreeMenu2Upgrade: {
    title: "FREE Menu Upgrade to Menu 3",
    description: "If you place an order similar to this quote, you qualify for FREE Menu Upgrade!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [0, 1, 2, 3, 4, 5],
    offeredMonths: [0, 1],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_2],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: true
  },
  deliveryHolidayMenu3GuacAndChips: {
    title: "Complimentary Guac & Chips",
    description: "If you place an order similar to this quote, you qualify for complimentary Guac & Chips!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [0, 1, 2, 3, 4, 5],
    offeredMonths: [0, 1],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_3],
    occasions: ["all"],
    dontShowIfItemsSelected: [600, 102],
    isSelectable: true
  },
  deliveryHolidayMenu3AquaFresca: {
    title: "Complimentary Aqua Fresca",
    description: "If you place an order similar to this quote, you qualify for complimentary Aqua Fresca!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [1, 2, 3, 4, 5],
    offeredMonths: [0, 1],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_3],
    occasions: ["all"],
    dontShowIfItemsSelected: [210],
    isSelectable: true
  },
  deliveryHolidayComplimentaryPicoDeGalloTacoBar: {
    title: "Complimentary Pico de Gallo (Taco Bar)",
    description: "If you place an order similar to this quote, you qualify for complimentary Pico de Gallo (Taco Bar)!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [0, 1, 2, 3],
    offeredMonths: [0, 1],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_4],
    occasions: ["all"],
    dontShowIfItemsSelected: [610, 103],
    isSelectable: true
  },
  deliveryHolidayComplimentaryQuesadillas: {
    title: "Complimentary Quesadillas",
    description: "If you place an order similar to this quote, you qualify for complimentary Quesadillas!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [0, 1, 2, 3],
    offeredMonths: [0, 1],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_4],
    occasions: ["all"],
    dontShowIfItemsSelected: [112, 113, 114, 605],
    isSelectable: true
  },
}